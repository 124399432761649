<template>
	<div id="app" class="container">
		<header class="header-module row no-gutters justify-content-between mb-0">
			<div class="col ehoi-logo">
				<a href="https://www.e-hoi.de" class="logo" v-if="$store.getters.getAppMandant == 101">
					<img class="img" src="@/assets/images/website-logo_101.png" alt />
				</a>
				<a href="https://www.cruisewinkel.nl" class="logo" v-if="$store.getters.getAppMandant == 104">
					<img class="img" src="@/assets/images/website-logo_104.png" alt />
				</a>
				<a href="https://www.wondercruises.be" class="logo" v-if="$store.getters.getAppMandant == 105">
					<img class="img" src="@/assets/images/website-logo_105.png" alt />
				</a>
				<a href="https://zeetours.nl" class="logo" v-if="$store.getters.getAppMandant == 106">
					<img class="img" src="@/assets/images/website-logo_106.jpg" alt />
				</a>
				<a href="https://www.cruisetravel.nl" class="logo" v-if="$store.getters.getAppMandant == 107">
					<img class="img" src="@/assets/images/website-logo_107.png" alt />
				</a>
				<a href="https://www.cruisetravel.nl" class="logo" v-if="$store.getters.getAppMandant == 109">
					<img class="img" src="@/assets/images/website-logo_109.png" alt />
				</a>
				<a href="https://www.cruisetravel.nl" class="logo" v-if="$store.getters.getAppMandant == 111">
					<img class="img" src="@/assets/images/website-logo_111.png" alt />
				</a>
				<a href="https://www.wondercruises.fr" class="logo" v-if="$store.getters.getAppMandant == 112">
					<img class="img" src="@/assets/images/website-logo_112.png" alt />
				</a>
			</div>
			<div class="col-auto tuv-logo text-left">
				<span class="hotline no-underline pro default" v-if="$store.getters.getAppMandant == 101">
					<span class="d-none d-lg-inline">
						<span>{{$t('personal_consulting')}}</span>
						<em>
							<i class="fa fa-phone"></i>
							<span class="phonenumber">+49 69 20456 - 788</span>
						</em>
						<a href="mailto:partnerprogramm@e-hoi.de" class="pro default">partnerprogramm@e-hoi.de</a>
					</span>
					<span class="d-inline d-lg-none mr-3">
						<i class="fa fa-phone"></i>
					</span>
				</span>
				<div class="hotline no-underline pro default" v-else-if="$store.getters.getAppMandant == 106">
					<span class="d-none d-lg-inline">
						<span>{{$t('personal_consulting')}}</span>
						<em>
							<i class="fa fa-phone"></i>
							<span class="phonenumber">010 28 23 800</span>
						</em>
						<a  href="mailto:sales@zeetours.nl" class="pro default">sales@zeetours.nl</a>
					</span>
					<span class="d-inline d-lg-none mr-3">
						<i class="fa fa-phone"></i>
					</span>
				</div>
			</div>
			<div class="col-auto d-flex">
				<Flags />
			</div>
			<div class="col-auto navbar-light">
				<button type="button" class="navbar-toggler collapsed d-block d-lg-none" data-toggle="collapse" aria-expanded="false"
					data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">
					<i class="fa fa-bars"></i>
				</button>
			</div>
		</header>
		<router-view />
		<Footer />
	</div>
</template>
<script>
	import Flags from "./views/Flags.vue";
	import Footer from "./views/Footer.vue";

	export default {
		components: { Flags, Footer },
		created(){
			if(this.$store.getters.getAppMandant == 104){
				window.document.body.classList.add("mandant-104");
				window.document.title = "cruisewinkel partnerware"
			}
			if(this.$store.getters.getAppMandant == 105){
				window.document.body.classList.add("mandant-105");
				window.document.title = "wondercruises partnerware"
			}
			if(this.$store.getters.getAppMandant == 106){
				window.document.body.classList.add("mandant-106");
				window.document.title = "zeetours partnerware"
			}
			if(this.$store.getters.getAppMandant == 107){
				window.document.body.classList.add("mandant-107");
				window.document.title = "cruisetravel partnerware"
			}
			if(this.$store.getters.getAppMandant == 109){
				window.document.body.classList.add("mandant-109");
				window.document.title = "ummundo partnerware"
			}
			if(this.$store.getters.getAppMandant == 111){
				window.document.body.classList.add("mandant-111");
				window.document.title = "ummundo partnerware"
			}
			if(this.$store.getters.getAppMandant == 112){
				window.document.body.classList.add("mandant-112");
				window.document.title = "wondercruises partnerware"
			}
		},
	};
</script>
<style>
	header{
		background-color: #ffffff;
		padding: 1.25rem;
	}

	header .pro em {
		font-family: Arial, sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 1.55em;
		margin: 0;
		display: block;
		color: #555;
	}

	header .pro span,
	header .pro a {
		color: #555;
		font-size: 1.1em;
		font-weight: 400;
		line-height: 1.55rem;
		margin: 0 5px 0 0;
	}

	header .logo img {
		height: 74px;
	}
	@media (max-width: 991px) {
		header{
			padding: .5rem;
		}
		header .logo img {
			height: 25px;
		}

		header .pro span,
		header .pro a {
			font-size: 1.75em;
		}
	}

	.tuv-logo {
		text-align: center;
	}

	.navmobile a {
		color: var(--bs-primary);
		display: block;
	}

	footer.login{
		max-width: 650px;
	}
</style>
