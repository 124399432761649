<template>
	<Layout>
		<div class="content">
			<SaveSpinner  :loadFlag="ui.loadingflag" />
			<div class="d-flex">
				<div class="mx-auto pb-4">
					<p class="font-weight-bold text-secondary" v-if="$store.getters.getAppMandant == 101">{{$t('home_welcome')}}</p>
					<p class="font-weight-bold text-secondary" v-if="$store.getters.getAppMandant == 104">{{$t('home_welcome_cw')}}</p>
					<p class="font-weight-bold text-secondary" v-if="$store.getters.getAppMandant == 105">{{$t('home_welcome_wc')}}</p>
					<p class="font-weight-bold text-secondary" v-if="$store.getters.getAppMandant == 106">{{$t('home_welcome_zt')}}</p>
					<p class="font-weight-bold text-secondary" v-if="$store.getters.getAppMandant == 107">{{$t('home_welcome_ct')}}</p>
					<p class="font-weight-bold text-secondary" v-if="$store.getters.getAppMandant == 109">{{$t('home_welcome_um')}}</p>
					<p class="font-weight-bold text-secondary" v-if="$store.getters.getAppMandant == 111">{{$t('home_welcome_um')}}</p>
					<p class="font-weight-bold text-secondary" v-if="$store.getters.getAppMandant == 112">{{$t('home_welcome_wc')}}</p>
				</div>
			</div>
			<div class="row">
				<div class="col-auto mx-auto" v-if="$store.getters.getAppMandant == 106">
					<a href="https://nieuws.sales.zeetours.nl/reisagenten-nieuwsbrief-aanmelden.jsp" class="text-secondary" target="blank" v-html="$t('overview_link_newsletter_anmeldung')"></a>
				</div>
				<div class="col-auto mx-auto">
					<a :href="getWLVpath('wlv/')" class="text-secondary" target="blank" v-html="$t('overview_link_IBE')"></a>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
	import Layout from "../layouts/Layout.vue";
	import SaveSpinner from "../views/Spinner.vue";
	//abstract page
	import Page from "../pages/Page.vue";
	//vuex
	import Vuex from "vuex";
	const ms = Vuex.mapState;
	export default {
		components: { Layout, SaveSpinner },
		mixins: [Page],
		computed: {
			partnerid_store() {
				return this.$store.state.partnerid;
			},
			...ms(["ui"])
		},
		methods: {
			getWLVpath: function(url){
				switch(this.$store.state.auth.mandant){
					case 101:
					return  process.env.VUE_APP_WLVPATH_101 + url + "?partnerid=" + this.$store.state.partnerid
					case 102:
					return process.env.VUE_APP_WLVPATH_102 + url + "?partnerid=" + this.$store.state.partnerid
					case 104:
					return process.env.VUE_APP_WLVPATH_104 + url + "?partnerid=" + this.$store.state.partnerid
					case 105:
					return process.env.VUE_APP_WLVPATH_105 + url + "?partnerid=" + this.$store.state.partnerid
					case 106:
					return process.env.VUE_APP_WLVPATH_106 + url + "?partnerid=" + this.$store.state.partnerid
					case 107:
					return process.env.VUE_APP_WLVPATH_107 + url + "?partnerid=" + this.$store.state.partnerid
					case 109:
					return process.env.VUE_APP_WLVPATH_109 + url + "?partnerid=" + this.$store.state.partnerid
					case 111:
					return process.env.VUE_APP_WLVPATH_111 + url + "?partnerid=" + this.$store.state.partnerid
					case 112:
					return process.env.VUE_APP_WLVPATH_112 + url + "?partnerid=" + this.$store.state.partnerid
				}
				return "";
			},
		}
	};
</script>
<style scoped>
	a{
		display: block;
		text-align: center;
	}
</style>