import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate' //be aware that only the paths defined below where this is used will be persisted! Some things, like ui, we do not want to persist
import runner from './runners/axiosrunner'
import router from './router'
import StackTrace from 'stacktrace-js'
import errorhandler from './errorhandler'


Vue.use(Vuex);

const fnTimedUiFlag = function(context){
  context.commit('setui' , {'successflag':true})
  setTimeout(function(){
    context.commit('setui' , {'successflag':false})
  },2500)
}

const fnIsDeployEnv = function(){
  return (process.env.NODE_ENV==='development') ? false : true
}
const getDefaultState = () => {
  return {
    arrerror : [],
    auth:{
        authtime: 1,
        status: '',
        token: '',
        user: {},
				partnerType: 1,
        accesstoken:1,
				pageViewsEnabled: false,
				mandant: 101,
				tradePartner: false
    },
    count: 0,
    hits:{},
		languages:[
			{id: 1, code:"de"},
			{id: 2, code:"en"},
			{id: 3, code:"fr"},
			{id: 4, code:"es"},
			{id: 5, code:"it"},
			{id: 6, code:"nl"},
			{id: 25, code:"fl"}
		],
    organizers: [],
		telnumbers: [],
		provisions: [],
		pageviews: [],
    passwordchange: {
      oldpassword:'',
      password1:'',
      password2:''
    },
    partnerid : 0,
    partnerstatistics:{},
    partnertopsorts:[],
    partnertopsortoptions:{
			destination: [],
			organizer: [],
			ship: []
		},
    partnerusers:[],
    reisebuero:{},
    bankaccounts:[],
    stammdaten:{},
    transactions:{},
    ui: {
      loadingflag: false,
      savingflag: false,
      errorflag:false,
      successflag:false,
      feedback:''
    },
    wlv:{
			styles: {},
			texts: []
		},
    yearlystats:{}
  }
}



// AXIOS interceptors
runner.axiosinterceptor.request.use(config => {
  // verify authentification with authtoken:
  if(typeof store.state.auth.token !== 'undefined'){
    config.headers.common['Authorization'] = store.state.auth.token
  }
  return config
})

runner.axiosinterceptor.response.use(response => {
  let objectKeysToLowerCase = function (input) {

    if(input==null){
      return input
    }
    if (typeof input !== 'object'){
      return input;
    }
    if (Array.isArray(input)){
      return input.map(objectKeysToLowerCase);
    }
    return Object.keys(input).reduce(function (newObj, key) {
      let val = input[key];
       let newVal = (typeof val === 'object') ? objectKeysToLowerCase(val) : val;
        newObj[key.toLowerCase()] = newVal;
        return newObj;
    }, {});
  };
  return objectKeysToLowerCase(response);
}, function(error){

  if (error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    if(error.response.status === 401 || error.response.status === 403){ //java api delivers 401, cf 403
      store.commit('auth_error',store.state); //reset authstate or we risk an infinite loop
      router.push({ name : 'login'});
      //window.location = '/login?invalid=true'; //not sure how to do this programmatically since we don't have access to the router in the store. careful here, changes my cause infinite loops.
    } else {
      //errors other than 401/3. Do we need a specific handler here. Probably not.
      return Promise.reject(error) //otherwise our error never propogates upwards and in fnErrorHandler we fail to come into the catch block
    }

  } else if (error.request) {
    /*
     * The request was made but no response was received, `error.request`
     * is an instance of XMLHttpRequest in the browser and an instance
     * of http.ClientRequest in Node.js
     */
    return Promise.reject(error) //otherwise our error never propogates upwards and in fnErrorHandler we fail to come into the catch block
  } else {
    // Something happened in setting up the request and triggered an Error
    return Promise.reject(error) //otherwise our error never propogates upwards and in fnErrorHandler we fail to come into the catch block
  }



})




//supposedly we only set actions here global to the entire application?? https://codeburst.io/composing-actions-with-vuex-b63466264a37
// !!! remove the strict: true when deploying to application. it hurts performance.
export const store = new Vuex.Store({
  strict: fnIsDeployEnv(),
  state: getDefaultState(),
  actions: {

    //for this small scale pilot app, I have chosen not to split out the entire store into separate modules, as supported by vuex. See docs for more info

		savereisebuero: (context,payload) => {
			//we return a promise to force the app to await a response. We will be refreshing the local store from the json returned by this method, and later the view too.
			return new Promise((resolve, reject) => {
				let action = "savepartner"

				context.commit('setreisebuero',payload.rb)
				context.commit('setbankaccounts',payload.ba)

				let body = JSON.stringify(payload.rb)
				context.commit('setui' , {'loadingflag':true})

				runner.doAPIPost(action,body,store.state.partnerid+"/save")
				.then(async resp => {
					context.commit('setreisebuero',resp.data)
					fnTimedUiFlag(context);

					await runner.doAPIPost(action,JSON.stringify(payload.ba),store.state.partnerid+"/bankaccounts/save")
					.then(resp2 => {
						context.commit('setbankaccounts',resp2.data)
						resolve(resp)
					})
				})
				.finally(function(){
					context.commit('setui' , {'loadingflag':false})
				})
				.catch(err => {
					context.commit('adduierror' , { 'errorflag':true , 'feedback':'unchanged_data' })
					let failure = errorhandler.failFormat(err,action,body)
					StackTrace.get().then(s => {
						failure.stack = s
						reject(failure)
					})
				})
			})
		},

    getreisebuero: (context) => {
      return new Promise((resolve, reject) => {
              let action = 'getpartner'
              return runner.doAPIGet(action, {},store.state.partnerid)
                .then(resp => {
                    context.commit('setreisebuero',resp.data)
                    resolve(resp)
                })
                .finally(function(){
                  context.commit('setui' , {'loadingflag':false})
                })
                .catch(err => {
                  let failure = errorhandler.failFormat(err,action,'')
                  StackTrace.get().then(s => {
                    failure.stack = s
                    Vue.config.errorHandler(failure,store.$app,'')
                    reject(failure)
                  })
                })
        })
    },

    getbackaccounts: (context) => {
      return new Promise((resolve, reject) => {
              let action = 'getpartner'
              return runner.doAPIGet(action, {},store.state.partnerid+"/bankaccounts")
                .then(resp => {
                    context.commit('setbankaccounts',resp.data)
                    resolve(resp)
                })
                .finally(function(){
                  context.commit('setui' , {'loadingflag':false})
                })
                .catch(err => {
                  let failure = errorhandler.failFormat(err,action,'')
                  StackTrace.get().then(s => {
                    failure.stack = s
                    Vue.config.errorHandler(failure,store.$app,'')
                    reject(failure)
                  })
                })
        })
    },

    listtransactions: (context,payload) => {
      return new Promise((resolve, reject) => {
              let action = 'listtransactions'
              return runner.doAPIGet(action, { 'startDate':payload.startdate, 'endDate':payload.enddate, 'searchIn':payload.searchin , 'type' : payload.type  } , store.state.partnerid+'/transactions' )
                  .then(resp => {
                    context.commit('settransactions',resp.data)
                    resolve(resp)
              })
              .catch(err => {
                let failure = errorhandler.failFormat(err,action,'')
                StackTrace.get().then(s => {
                  failure.stack = s
                  Vue.config.errorHandler(failure,store.$app,'')
                  reject(failure)
                })
              })
        })
    },

    listyearlystats: (context,payload) => {
      return new Promise((resolve, reject) => {
        let action = 'listyearlystats'
        return runner.doAPIGet(action, { 'year':payload.year , 'type' : payload.type  } , store.state.partnerid )
                  .then(resp => {
                    context.commit('setyearlystats',resp.data)
                    resolve(resp)
              })
              .catch(err => {
                  let failure = errorhandler.failFormat(err,action,'')
                  StackTrace.get().then(s => {
                    failure.stack = s
                    Vue.config.errorHandler(failure,store.$app,'')
                    reject(failure)
                  })
              })
        })
    },

    getpartnerhits: (context,payload) => {
      return new Promise((resolve, reject) => {
              let action = 'getpartnerhits'
              return runner.doAPIGet(action, { 'startDate':payload.startdate , 'endDate' : payload.enddate  } , store.state.partnerid+"/hits" )
              //return runner.doGet({'action':action, 'partnerid':store.state.partnerid, 'startdate':payload.startdate, 'enddate':payload.enddate})
                  .then(resp => {
                    context.commit('sethits',resp.data)
                    resolve(resp)
              })
              .catch(err => {
                  let failure = errorhandler.failFormat(err,action,'')
                  StackTrace.get().then(s => {
                    failure.stack = s
                    Vue.config.errorHandler(failure,store.$app,'')
                    reject(failure)
                  })
              })
        })
    },
    getpartnerpageviews: (context,payload) => {
      return new Promise((resolve, reject) => {
              let action = 'getpartnerhits'
              return runner.doAPIGet(action, { 'startDate':payload.startdate , 'endDate' : payload.enddate  } , store.state.partnerid+"/pageview" )
              //return runner.doGet({'action':action, 'partnerid':store.state.partnerid, 'startdate':payload.startdate, 'enddate':payload.enddate})
                  .then(resp => {
                    context.commit('setpageviews',resp.data)
                    resolve(resp)
              })
              .catch(err => {
                  let failure = errorhandler.failFormat(err,action,'')
                  StackTrace.get().then(s => {
                    failure.stack = s
                    Vue.config.errorHandler(failure,store.$app,'')
                    reject(failure)
                  })
              })
        })
    },

		getpartnerstatistics: (context) => {
			return new Promise((resolve, reject) => {
        let action = 'getpartnerstatistics'
        return runner.doAPIGet(action, {},store.state.partnerid)
				.then(resp => {
          var gesamt = {
						bak: 0,
						booking_count: 0,
						bookinggesamt_sum: 0,
						bookingrequest_count: 0,
						jahre: [],
						personen: 0,
						stornos: 0,
						umbook: 0,
						visits: 0
					};
					var gesamt_jahr;
					var currYear = 0;
					var count = 0;

					resp.data.forEach((item) => {
						item.umbook = (Math.round(item.umbook * 100) / 100).toFixed(2) * 1;
						count ++;
						item.personen != null ? item.personen*1 : item.personen = 0;
						if(item.jahr !== currYear){
							currYear = item.jahr;
							gesamt_jahr = Object.assign({}, item);
							delete gesamt_jahr.monat;
							gesamt_jahr.statistics = [];
							gesamt.jahre.push(gesamt_jahr);
						} else {
							gesamt_jahr.bak += item.bak;
							gesamt_jahr.booking_count += item.booking_count;
							gesamt_jahr.bookinggesamt_sum += item.bookinggesamt_sum;
							gesamt_jahr.bookingrequest_count += item.bookingrequest_count;
							gesamt_jahr.personen += item.personen;
							gesamt_jahr.stornos += item.stornos;
							gesamt_jahr.umbook += item.umbook;
							gesamt_jahr.visits += item.visits;
						}
						gesamt_jahr.statistics.push(item);

						gesamt.bak += item.bak;
						gesamt.booking_count += item.booking_count;
						gesamt.bookinggesamt_sum += item.bookinggesamt_sum;
						gesamt.bookingrequest_count += item.bookingrequest_count;
						gesamt.personen += item.personen;
						gesamt.stornos += item.stornos;
						gesamt.umbook += item.umbook;
						gesamt.visits += item.visits;
					});

					gesamt.umbook = (Math.round((gesamt.umbook / count) * 100) / 100).toFixed(2) * 1;
					gesamt.bookinggesamt_sum = (Math.round(gesamt.bookinggesamt_sum * 100) / 100).toFixed(2) * 1;

					gesamt.jahre.forEach((item) => {
						item.umbook = (Math.round((item.umbook / item.statistics.length) * 100) / 100).toFixed(2) * 1;
						item.bookinggesamt_sum = (Math.round(item.bookinggesamt_sum * 100) / 100).toFixed(2) * 1;
					});

					context.commit('setpartnerstatistics',gesamt)
					resolve(resp)
				})
				.catch(err => {
          let failure = errorhandler.failFormat(err,action,'')
          StackTrace.get().then(s => {
            failure.stack = s
            Vue.config.errorHandler(failure,store.$app,'')
            reject(failure)
          })
				})
			})
		},

		getpartnertopsort: (context) => {
			return new Promise((resolve, reject) => {
				let action = 'getpartnertopsort'
				return runner.doAPIGet(action, {},store.state.partnerid+"/topsort")
				.then(resp => {
					context.commit('setpartnertopsort',resp.data)
					resolve(resp)
				})
				.catch(err => {
					let failure = errorhandler.failFormat(err,action,'')
					StackTrace.get().then(s => {
						failure.stack = s
						Vue.config.errorHandler(failure,store.$app,'')
						reject(failure)
					})
				})
			})
		},
		gettopsortorganizer: (context) => {
			return new Promise((resolve, reject) => {
				let action = 'getorganizer'
				return runner.doAPIGet(action, {}, "searchOptions?mandant=" + store.state.auth.mandant)
				.then(resp => {
					context.commit('settopsortoptionsorganizer',resp.data)
					resolve(resp)
				})
				.catch(err => {
					let failure = errorhandler.failFormat(err,action,'')
					StackTrace.get().then(s => {
						failure.stack = s
						Vue.config.errorHandler(failure,store.$app,'')
						reject(failure)
					})
				})
			})
		},
		gettopsortdestination: (context) => {
			return new Promise((resolve, reject) => {
				let action = 'getdestination'
				return runner.doAPIGet(action, {}, "cruisingarea/searchOptions")
				.then(resp => {
					context.commit('settopsortoptionsdestination',resp.data)
					resolve(resp)
				})
				.catch(err => {
					let failure = errorhandler.failFormat(err,action,'')
					StackTrace.get().then(s => {
						failure.stack = s
						Vue.config.errorHandler(failure,store.$app,'')
						reject(failure)
					})
				})
			})
		},
		gettopsortship: (context) => {
			return new Promise((resolve, reject) => {
				let action = 'getship'
				return runner.doAPIGet(action, {}, "searchOptions?mandant=" + store.state.auth.mandant)
				.then(resp => {
					context.commit('settopsortoptionsship',resp.data)
					resolve(resp)
				})
				.catch(err => {
					let failure = errorhandler.failFormat(err,action,'')
					StackTrace.get().then(s => {
						failure.stack = s
						Vue.config.errorHandler(failure,store.$app,'')
						reject(failure)
					})
				})
			})
		},
		savetopsort: (context, payload) => {
			return new Promise((resolve, reject) => {
        let action = 'getpartner'
        // context.commit('setorganizers', payload.org)
        let body = JSON.stringify(payload.topsort)
				// context.commit('setui' , {'loadingflag':true})
        runner.doAPIPost(action,body,store.state.partnerid+"/topsort")
				.then(resp => {
					context.commit('setorganizers',resp.data)
					fnTimedUiFlag(context)
					resolve(resp)
				})
				.finally(function(){
					// context.commit('setui' , {'loadingflag':false})
				})
				.catch(err => {
					context.commit('adduierror' , { 'errorflag':true })
          let failure = errorhandler.failFormat(err,action,body)
          StackTrace.get().then(s => {
            failure.stack = s
            reject(failure)
          })
				})
			})
		},
		listTelnumbers: (context) => {
			return new Promise((resolve, reject) => {
				let action = 'gettelnumbers';

				return runner.doAPIGet(action, {}, "telnumbers")
				.then(resp => {
					resp.data.sort((a,b) => {
						if(a.countrycode > b.countrycode){return 1}
						if(a.countrycode < b.countrycode){return -1}
						return 0
					})
					context.commit('settelnumbers',resp.data)
					resolve(resp)
				})
				.finally(function(){
					context.commit('setui' , {'loadingflag':false})
				})
				.catch(err => {
					let failure = errorhandler.failFormat(err, action, "");
					StackTrace.get().then(s => {
						failure.stack = s
						Vue.config.errorHandler(failure,store.$app,'')
						reject(failure)
					})
				})
			})
		},
		listorganizer: (context) => {
			return new Promise((resolve, reject) => {
        let action = 'listPartnerOrganizerData'

        return runner.doAPIGet(action, {},store.state.partnerid+"/organizer/list")
          .then(resp => {
            context.commit('setorganizers',resp.data)
            resolve(resp)
          })
          .catch(err => {
            let failure = errorhandler.failFormat(err,action,'')
            StackTrace.get().then(s => {
              failure.stack = s
              Vue.config.errorHandler(failure,store.$app,'')
              reject(failure)
            })
          })
      })
		},
    saveorganizer: (context, payload) => {
			return new Promise((resolve, reject) => {
        let action = 'savePartnerOrganizerData'
        context.commit('setorganizers', payload.org)
        let body = JSON.stringify(payload.org)
				context.commit('setui' , {'loadingflag':true})
        runner.doAPIPost(action,body,store.state.partnerid+"/organizers/save")
				.then(resp => {
					context.commit('setorganizers',resp.data)
					fnTimedUiFlag(context)
					resolve(resp)
				})
				.finally(function(){
					context.commit('setui' , {'loadingflag':false})
				})
				.catch(err => {
					context.commit('adduierror' , { 'errorflag':true , 'feedback':'unchanged_data' })
          let failure = errorhandler.failFormat(err,action,body)
          StackTrace.get().then(s => {
            failure.stack = s
            reject(failure)
          })
				})
			})
		},

    getstammdaten: (context) => {
        return new Promise((resolve, reject) => {
              let action = 'getstammdaten'
              runner.doAPIGet(action, {"standardValueIds" : "1,2"},"standardvalues")
              .then(resp => {
                //Inject fixed items.
                resp.data.fontstyle =  [ { "text" : "Arial" , "value":"Arial"} , { "text" : "Helvetica", "value":"Helvetica" } , { "text" : "sans-serif", "value":"sans-serif" }, { "text" : "Tahoma", "value":"Tahoma" }, { "text" : "Trebuchet MS", "value":"Trebuchet MS" }, { "text" : "Verdana", "value":"Verdana" }, { "text" : "Open Sans", "value":"Open Sans" }, { "text" : "Time New Roman", "value":"Time New Roman" }, { "text" : "Courier New", "value":"Courier New" }, { "text" : "Georgia", "value":"Georgia" }, { "text" : "Lucida Sans", "value":"Lucida Sans" } ]
                resp.data.transactiontypes = [ {"text" : "Buchungsanfragen" , "value":"request" } , {"text" : "Buchungen" , "value":"open" },  {"text" : "Stornierte Buchungen" , "value":"storno" } ]
                context.commit('setstammdaten',resp.data)
                    resolve(resp)
              })
              .finally(function(){
                context.commit('setui' , {'loadingflag':false})
              })
              .catch(err => {
                let failure = errorhandler.failFormat(err,action,'')
                StackTrace.get().then(s => {
                  failure.stack = s
                  reject(failure)
                })
              })
        })
    },

    checkpartner(context, username) {
            return new Promise((resolve, reject) => {
              let body = {
                username :username
                 }
              let action = "checkpartner"
              runner.doAPIPost(action, body,'')
                .then(resp => {
                  resolve(resp.data)
                })
                .catch(err => {
                  context.commit('auth_error')
                  let failure = errorhandler.failFormat(err,action,body)
                  StackTrace.get().then(s => {
                    failure.stack = s
                    reject(failure)
                  })
                })

              })
    },


    login(context, login) {
            return new Promise((resolve, reject) => {
              context.commit('auth_request')
              let body = {
                username : login.username,
                password : login.password
                 }
              let action = "authentication"
              runner.doAPIPost(action, body,'')
                .then(resp => {
                  context.commit('auth_success', {
										token: resp.data.authtoken,
										partnerid: resp.data.partnerid,
										user: resp.data.user,
										accesstoken: resp.data.accesstoken,
										pageviewsenabled: resp.data.pagetrakingenabled,
										mandant: resp.data.mandant,
										tradePartner: resp.data.tradepartner
									})
                  resolve(resp)
                })
                .catch(err => {
                  context.commit('auth_error')
                  let failure = errorhandler.failFormat(err,action,body)
                  StackTrace.get().then(s => {
                    failure.stack = s
                    reject(failure)
                  })
                })

              })
    },
    loginehoi(context, partnerid) {
            return new Promise((resolve, reject) => {
              context.commit('auth_request')
              let body = {partnerid: partnerid}
              let action = "loginehoi"
              runner.doAPIPost(action, body,'')
                .then(resp => {
                  context.commit('auth_success', {
										token: resp.data.authtoken,
										partnerid: resp.data.partnerid,
										user: resp.data.user,
										accesstoken: resp.data.accesstoken,
										pageviewsenabled: resp.data.pagetrakingenabled,
										mandant: resp.data.mandant,
										tradePartner: resp.data.tradepartner
									})
                  resolve(resp)
                })
                .catch(err => {
                  context.commit('auth_error')
                  let failure = errorhandler.failFormat(err,action,body)
                  StackTrace.get().then(s => {
                    failure.stack = s
                    reject(failure)
                  })
                })

              })
    },

    logout({ commit }) {
            return new Promise((resolve) => {
              commit('resetState')
              localStorage.removeItem('token')
              localStorage.removeItem('vuex')
              delete axios.defaults.headers.common['Authorization']
              resolve()
            })
    },

		passwordreset(context, payload){
			return new Promise((resolve, reject) => {
				let action = "passwordreset"
				context.commit('setui' , {'loadingflag':true})
				runner.doAPIPost(action, payload,'')
				.then(resp => {
					resolve(resp)
				})
				.catch(err => {
					context.commit('adduierror' , { 'errorflag':true , 'feedback':'email_not_send' })
					let failure = errorhandler.failFormat(err,action,payload)
					StackTrace.get().then(s => {
						failure.stack = s
						reject(failure)
					})
				})
			})
		},

    passwordchange(context, payload) {
          return new Promise((resolve, reject) => {
            let body = {
              userName : store.state.auth.user,
              oldPassword : payload.pc.oldpassword,
              newPassword : payload.pc.password1,
              confirmPassword : payload.pc.password2
            }
            let action = "passwordchange"
            context.commit('setui' , {'loadingflag':true})
            runner.doAPIPost(action, body,'')
                .then(resp => {
                  if(typeof resp == 'undefined' || resp.data.oldPassword != resp.data.newPassword){ //if successfully updated, the API will return old and new passwords as being the same
                    context.commit('adduierror' , { 'errorflag':true , 'feedback':'unchanged_password' })
                  } else {
                    context.commit('setpasswordchange',payload.pc)
                    fnTimedUiFlag(context)
                  }
                  resolve(resp)
                })
                .finally(function(){
                  context.commit('setui' , {'loadingflag':false})
                })
                .catch(err => {
                  context.commit('adduierror' , { 'errorflag':true , 'feedback':'unchanged_password' })
                  let failure = errorhandler.failFormat(err,action,body)
                  StackTrace.get().then(s => {
                    failure.stack = s
                    reject(failure)
                  })
                })
       })
    },

    clearpassworddata: (context,payload) => context.commit('clearpassworddata',payload),

    getwlv: (context) => {
      return new Promise((resolve, reject) => {
        let action = 'getwlv'
        return runner.doAPIGet(action, {},store.state.partnerid)
            .then(resp => {
              context.commit('setwlv',resp.data)
              resolve(resp)
        })
        .finally(function(){
          context.commit('setui' , {'loadingflag':false})
        })
        .catch(err => {
          let failure = errorhandler.failFormat(err,action,'')
          StackTrace.get().then(s => {
            failure.stack = s
            Vue.config.errorHandler(failure,store.$app,'')
            reject(failure)
          })
        })
      })
    },

    savewlv: (context,payload) => {
      //we return a promise to force the app to await a response. We will be refreshing the local store from the json returned by this method, and later the view too.
      return new Promise((resolve, reject) => {
        let action = 'savewlv'
        context.commit('setwlv',payload.wlv)
        let body = JSON.stringify(payload.wlv)
        context.commit('setui' , {'loadingflag':true})
        runner.doAPIPost(action,body,store.state.partnerid+"/save")
        .then(resp => {
                  context.commit('setwlv',resp.data)
                  fnTimedUiFlag(context)
                  resolve(resp)
        })
        .finally(function(){
          context.commit('setui' , {'loadingflag':false})
        })
        .catch(err => {
                    context.commit('adduierror' , { 'errorflag':true , 'feedback':'unchanged_data' })
                    let failure = errorhandler.failFormat(err,action,body)
                    StackTrace.get().then(s => {
                      failure.stack = s
                      reject(failure)
                    })
         })
      })
    },

    savepartneruser: (context,payload) => {
      //we return a promise to force the app to await a response. We will be refreshing the local store from the json returned by this method, and later the view too.
      return new Promise((resolve, reject) => {
      let action = 'savepartneruser'
      payload.pu.partnerid = store.state.partnerid
      let body = payload.pu
      context.commit('setui' , {'loadingflag':true})
      runner.doAPIPost(action,body,store.state.partnerid+"/user/save")
        .then(resp => {
                  context.commit('setpartnerusers',resp.data)
                  fnTimedUiFlag(context)
                  resolve(resp)
        })
        .finally(function(){
          context.commit('setui' , {'loadingflag':false})
        })
        .catch(err => {
                    context.commit('adduierror' , { 'errorflag':true , 'feedback':'unchanged_data' })
                    let failure = errorhandler.failFormat(err,action,body)
                    StackTrace.get().then(s => {
                      failure.stack = s
                      reject(failure)
                    })
        })
      })
    },

    deletepartneruser: (context,payload) => {
      return new Promise((resolve, reject) => {
          let action = 'deletepartneruser'
          runner.doAPIDelete(action,{},store.state.partnerid+"/user/"+payload.username)
          .then(resp => {
            fnTimedUiFlag(context)
                    resolve(resp)
          })
          .catch(err => {
                      context.commit('adduierror' , { 'errorflag':true , 'feedback':'unchanged_data' })
                      let failure = errorhandler.failFormat(err,action,payload)
                      StackTrace.get().then(s => {
                        failure.stack = s
                        reject(failure)
                      })
           })
        })
    },



    ///121714000000/user/list
    listpartnerusers: (context) => {
      return new Promise((resolve, reject) => {
        let action = 'listpartnerusers'
        return runner.doAPIGet(action, {},store.state.partnerid+"/user/list")
          .then(resp => {
            context.commit('setpartnerusers',resp.data)
            resolve(resp)
          })
          .catch(err => {
            let failure = errorhandler.failFormat(err,action,'')
            StackTrace.get().then(s => {
              failure.stack = s
              reject(failure)
            })
          })
      })
    },

		listProvisions: (context, payload) => {
			return new Promise((resolve, reject) => {
				let action = 'listProvisions';

				return runner.doAPIGet(action, {}, `${store.state.partnerid}/provisions?month=${payload.month}&year=${payload.year}`)
				.then(resp => {
					context.commit('setprovisions',resp.data)
					resolve(resp)
				})
				.finally(function(){
					context.commit('setui' , {'loadingflag':false})
				})
				.catch(err => {
					let failure = errorhandler.failFormat(err, action, "");
					StackTrace.get().then(s => {
						failure.stack = s
						Vue.config.errorHandler(failure,store.$app,'')
						reject(failure)
					})
				})
			})
		},

		getProvisionFile: (context, payload) => {
			return new Promise((resolve, reject) => {
				let action = 'listProvisions';

				return runner.doBlob(action, payload, `${store.state.partnerid}/provision/download`, {
					"Authorization": store.state.auth.token,
					"Content-Type": "application/json;charset=utf-8",
					"Accept": "application/json;charset=utf-8"
				})
				.then(resp => {
					if(resp.status >= 400){
						throw(resp);
					}

					resolve(resp.blob())
				})
				.catch(err => {
					let failure = errorhandler.failFormat(err, action, "");
					StackTrace.get().then(s => {
						failure.stack = s
						Vue.config.errorHandler(failure,store.$app,'')
						reject(failure)
					})
				})
			})
		},

  }, //end of actions

  mutations: {
    increment: state => state.count++,
    decrement: state => state.count--,
    setreisebuero: (state,payload) => state.reisebuero = payload,
    setbankaccounts: (state,payload) => state.bankaccounts = payload,
    setstammdaten: (state,payload) => state.stammdaten = payload,
    settransactions: (state,payload) => state.transactions = payload,
    setpartnerstatistics: (state,payload) => state.partnerstatistics = payload,
    setpartnertopsort: (state,payload) => state.partnertopsorts = payload,
    settopsortoptionsdestination: (state,payload) => {state.partnertopsortoptions.destination = payload},
    settopsortoptionsorganizer: (state,payload) => {state.partnertopsortoptions.organizer = payload},
    settopsortoptionsship: (state,payload) => {state.partnertopsortoptions.ship = payload},
    setorganizers: (state,payload) => state.organizers = payload,
    settelnumbers: (state,payload) => state.telnumbers = payload,
    setprovisions: (state,payload) => state.provisions = payload,
    sethits: (state,payload) => state.hits = payload,
    setpageviews: (state,payload) => state.pageviews = payload,
    setyearlystats: (state,payload) => state.yearlystats = payload,
    setwlv: (state,payload) => state.wlv = payload,
    setpartnerusers: (state,payload) => state.partnerusers = payload,
    setpasswordchange: (state,payload) => {
                                            state.passwordchange = payload
                                          },
    clearpassworddata: (state) => {
      state.passwordchange.oldpassword  = ''
      state.passwordchange.password1    = ''
      state.passwordchange.password2    = ''

    },
    setui: (state,payload) => {
      Object.assign(state.ui,payload)
    },
    resetui: (state) => {
      state.ui.errorflag = false,
      state.ui.feedback  = '',
      state.ui.loadingflag = false
    },
    adduierror: (state , payload) => {
      state.ui.errorflag = payload.errorflag,
      state.ui.feedback  = payload.feedback
    },
    auth_request(state) {
            state.auth.status = 'loading'
    },
    auth_success(state, payload) {
            state.partnerid = payload.partnerid
            state.auth.status = 1
            state.auth.token = payload.token
            state.auth.accesstoken = payload.accesstoken
            state.auth.user = payload.user
						state.auth.partnerType = 1
            state.auth.authtime = Date.now();
						state.auth.pageViewsEnabled = payload.pageviewsenabled;
						state.auth.mandant = payload.mandant;
						state.auth.tradePartner = payload.tradePartner;
    },
    auth_error(state) {
            state.auth.status = 'error'
    },
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  },

  plugins: [createPersistedState({
              paths:['auth' , 'partnerid' , 'stammdaten', 'reisebuero' ]
            })],

  getters:{ //if the user has no auth token, or the authtime older than one hour ago, we force login page. Note that even if the user manipulates this client-side, an invalid token will still result in no data from the REST requests. This scenario is primarily in place to force redirects to login for old sessions on pages where no rest request is made, and thus no session validation.
    isLoggedIn: state => {
      return (!!state.auth.token && Date.now() < state.auth.authtime+(1000*60*60*2))
    },
    getAccessToken: state => {
      return state.auth.accesstoken
    },
    getAppMandant: () => {
			if(window.location.hostname == process.env.VUE_APP_MANDANT_104){
				return 104;
			}
			if(window.location.hostname == process.env.VUE_APP_MANDANT_105){
				return 105;
			}
			if(window.location.hostname == process.env.VUE_APP_MANDANT_106){
				return 106;
			}
			if(window.location.hostname == process.env.VUE_APP_MANDANT_107){
				return 107;
			}
			if(window.location.hostname == process.env.VUE_APP_MANDANT_109){
				return 109;
			}
			if(window.location.hostname == process.env.VUE_APP_MANDANT_111){
				return 111;
			}
			if(window.location.hostname == process.env.VUE_APP_MANDANT_112){
				return 112;
			}
      return 101
    },
    authStatus: state => state.auth.status,
		isPageViewsEnabled: state => state.auth.pageViewsEnabled,
		isTradePartner: state => state.auth.tradePartner
  }


});