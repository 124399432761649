import Home from './pages/Home.vue'
import Login from './pages/Login.vue'
import Loginehoi from './pages/Loginehoi.vue'
import PartnerData from './pages/PartnerData.vue'
import TopSort from './pages/TopSort.vue'
import PartnerReport from './pages/PartnerReport.vue'
import Password from './pages/Password.vue'
import WLVData from './pages/WLVData.vue'
import User from './pages/User.vue'
import Organizer from './pages/Organizer.vue'
import Statistics from './pages/Statistics.vue'
import Transactions from './pages/Transactions.vue'
import IcbeData from './pages/icbe/IcbeData.vue'
import TransactionTable from './views/TransactionTable.vue'
import HitTable from './views/HitTable.vue'
import Provisions from './pages/Provisions.vue'



//first two are generic, unnamed routes. the last one is mapped to a different, named view. Also shows how to set multiple views at once.
const routes = [
    {
        path: '/index.html', redirect: '/home'
    },
    {
        path: '/', redirect: '/home'
    },
    {
      path: '/home',
      component: Home,
      meta: {requiresAuth:true,accessLevel:1}
    },
    {
        path: '/login', component: Login , name:'login' ,  meta: {requiresAuth:false,accessLevel:1}, props:(route)=>({invalid:(route.query.invalid=="true")})
    },
    {
        path: '/loginehoi', component: Loginehoi , name:'loginehoi' ,  meta: {requiresAuth:false,accessLevel:1}, props:(route)=>({invalid:(route.query.invalid=="true")})
    },
    {
      path: '/logout', component: Login , name:'logout' , meta: {requiresAuth:false,accessLevel:1}, props:{logout:true}
    },
    {
        path: '/partnerdata/edit', component: PartnerData , name:'partnerdata', meta: {requiresAuth:true, accessLevel:2}
    },
    {
        path: '/partnerdata/topsort', component: TopSort , name:'topsort', meta: {requiresAuth:true, accessLevel:2}
    },
    {
        path: '/partnerdata/password', component: Password , name:'password', meta: {requiresAuth:true, accessLevel:4}
    },
    {
        path: '/partnerdata/users', component: User , name:'user', meta: {requiresAuth:true,accessLevel:256}
    },
    {
        path: '/partnerdata/organizers', component: Organizer , name:'organizer', meta: {requiresAuth:true,accessLevel:256}
    },
    {
        path: '/icbe/data/edit', component: IcbeData , name:'icbedata' , meta: {requiresAuth:true,accessLevel:256}
    },
    {
        path: '/wlv/edit', component: WLVData , name:'wlvdata' , meta: {requiresAuth:true,accessLevel:32}
    },
    {
        path: '/statistics/bookingreport', component: Transactions , name:'transactions' , meta: {requiresAuth:true,accessLevel:1}
    },
    {
        path: '/statistics/traffic', component: Statistics,
                                     name:'statistics',
                                     meta: {requiresAuth:true,accessLevel:1},
                                     children: [
                                         {
                                             name:'monthlytraffic',
                                             path:'monthly/:startdate',
                                             component:TransactionTable,
                                             meta: {requiresAuth:true,accessLevel:1},
                                             props:true
                                         },
                                         {
                                             name:'monthlyrequests',
                                             path:'requests/:startdate',
                                             component:TransactionTable,
                                             meta: {requiresAuth:true,accessLevel:1},
                                             props:true
                                         },
                                         {
                                            name:'monthlyhits',
                                            path:'hits/:startdate',
                                            component:HitTable,
                                            meta: {requiresAuth:true,accessLevel:1},
                                            props:true
                                        },
                                        {
                                           name:'monthlypageviews',
                                           path:'pageview/:startdate',
                                           component:HitTable,
                                           meta: {requiresAuth:true,accessLevel:1},
                                           props:true
                                       }
                                     ]
    },
    {
        path: '/statistics/overview', component: PartnerReport , name:'partnerreport'  , meta: {requiresAuth:true,accessLevel:1}
    },
    {
        path: '/provisions', component: Provisions , name:'provisions', meta: {requiresAuth:true,accessLevel:1}
    },

];


export default routes;