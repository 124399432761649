<template>
	<Layout>
		<div>
			<SaveSpinner  :loadFlag="ui.loadingflag" />
			<div v-if="error">
				<div class="alert alert-danger">
					<Error />
				</div>
			</div>
			<div v-else-if="loadingflag === false">
				<div class="content">
					<form @submit.prevent="saveWLV">
						<p class="span-style pl-3">{{$t('page_background')}}</p>
						<div class="mb-4">
							<div class="card-body">
								<div class="row">
									<div class="col-md-7">
										<FEC :n="$t('logo_header')">
											<VFile class="btn btn-info" nn="PartnerLogoImage" v-on:change="changeLogoName" :a="`image/gif,image/jpeg,image/pjpeg,image/png,image/x-png`" :n="$t('change_file')" />
											<span>{{partnerLogo !== null ? partnerLogo.name : ''}}</span>
											<div class="d-block partnerLogo">
												<img :src="partnerLogoURL" :alt="$t('logo')" />
											</div>
										</FEC>
									</div>
									<div class="col-md-5">{{$t('logo_layout')}}</div>
								</div>
								<div class="row">
									<div class="col-md-7">
										<FEC :n="$t('phonenumber')">
											<div class="input-group">
												<select class="form-control w-50" v-model="wlv.phonecountry">
													<option value=""></option>
													<optgroup label="meist benutzt">
														<option v-for="tel in recomTelnumbers" v-bind:key="tel.countrycode" v-bind:value="tel.phonecode">
															({{tel.countrycode}}) {{tel.phonecode}}
														</option>
													</optgroup>
													<optgroup label="country">
														<option v-for="tel in telnumbers" v-bind:key="tel.countrycode" v-bind:value="tel.phonecode" >
															({{tel.countrycode}}) {{tel.phonecode}}
														</option>
													</optgroup>
												</select>
												<VText class="px-1 w-25" v-model="wlv.phonecity" />
												<VText class="px-1 w-25" v-model="wlv.phonenumber" />
											</div>
										</FEC>
									</div>
									<div class="col-md-5">{{$t('service_layout', [getPhoneCountry()])}}</div>
								</div>
								<div class="row">
									<div class="col-md-7">
										<FEC :n="$t('display_header')">
											<VCheckbox v-model="wlv.styles.blockheader" />
										</FEC>
									</div>
									<div class="col-md-5">{{$t('header_layout')}}</div>
								</div>
								<div class="row">
									<div class="col-md-7">
										<FEC :n="$t('display_footer')">
											<VCheckbox v-model="wlv.styles.blockfooter" />
										</FEC>
									</div>
									<div class="col-md-5">{{$t('footer_layout')}}</div>
								</div>
							</div>
						</div>
						<p class="span-style pl-3">{{$t('color_background')}}</p>
						<div class="mb-4">
							<div class="card-body">
								<div class="row">
									<div class="col-md-6">
										<FEC :n="$t('ibe_background')">
											<VColorpicker v-model="wlv.styles.colorbackground" :r="'colorbackground'" />
										</FEC>
									</div>
									<div class="col-md-6">{{$t('ibe_background_page')}}</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<FEC :n="$t('colorHeading')">
											<VColorpicker v-model="wlv.styles.colorheading" :r="'colorheading'" />
										</FEC>
									</div>
									<div class="col-md-6">{{$t('colorHeading_info')}}</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<FEC :n="$t('colorprimary')">
											<VColorpicker v-model="wlv.styles.colorprimary" :r="'colorprimary'" />
										</FEC>
									</div>
									<div class="col-md-6">{{$t('colorprimary_info')}}</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<FEC :n="$t('colorsecondary')">
											<VColorpicker v-model="wlv.styles.colorsecondary" :r="'colorsecondary'" />
										</FEC>
									</div>
									<div class="col-md-6">{{$t('colorsecondary_info')}}</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<FEC :n="$t('colorbutton')">
											<VColorpicker v-model="wlv.styles.colorbutton" :r="'colorbutton'" />
										</FEC>
									</div>
									<div class="col-md-6">{{$t('colorbutton_info')}}</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<FEC :n="$t('colorbuttonhover')">
											<VColorpicker v-model="wlv.styles.colorbuttonhover" :r="'colorbuttonhover'" />
										</FEC>
									</div>
									<div class="col-md-6">{{$t('colorbuttonhover_info')}}</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<FEC :n="$t('colorbuttonfont')">
											<VColorpicker v-model="wlv.styles.colorbuttonfont" :r="'colorbuttonfont'" />
										</FEC>
									</div>
									<div class="col-md-6">{{$t('colorbuttonfont_info')}}</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<FEC :n="$t('colorlink')">
											<VColorpicker v-model="wlv.styles.colorlink" :r="'colorlink'" />
										</FEC>
									</div>
									<div class="col-md-6">{{$t('colorborder_info')}}</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<FEC :n="$t('colorborder')">
											<VColorpicker v-model="wlv.styles.colorborder" :r="'colorborder'" />
										</FEC>
									</div>
									<div class="col-md-6">{{$t('colorborder_info')}}</div>
								</div>
							</div>
						</div>
						<p class="span-style pl-3">{{$t('font_background')}}</p>
						<div class="mb-4">
							<div class="card-body">
								<div class="row">
									<div class="col-md-6">
										<FEC :n="$t('font_width')">
											<VText v-model="wlv.styles.fontsize" />
										</FEC>
									</div>
									<div class="col-md-6">{{$t('fontsize_info')}}</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<FEC :v="$v.wlv.styles.fontfamily" :n="$t('font_art')">
											<VSelect v-model="wlv.styles.fontfamily" :q="ref_font" :v="$v.wlv.styles.fontfamily" />
										</FEC>
									</div>
									<div class="col-md-6">{{$t('fontfamily_info')}}</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<FEC :n="$t('font_color')">
											<VColorpicker v-model="wlv.styles.colorfont" :r="'colorfont'" />
										</FEC>
									</div>
									<div class="col-md-6">{{$t('colorfont_info')}}</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<FEC :n="$t('font_price_color')">
											<VColorpicker v-model="wlv.styles.colorfonthighlight" :r="'colorfonthighlight'" />
										</FEC>
									</div>
									<div class="col-md-6">{{$t('colorfonthighlight_info')}}</div>
								</div>
							</div>
						</div>
						<div>
							<p class="alert alert-info text-center">{{$t("wlv_update_info")}}</p>
						</div>
						<div class="text-center">
							<button type="submit" class="btn btn-info mr-3">
								<i class="fa fa-save"></i> {{$t('save')}}
							</button>
							<a :href="getWLVpath('wlv/')" target="_blank" class="btn btn-outline-info">
								WLV-Seite <i style="color: var(--bs-primary)" class="fa fa-external-link"></i>
							</a>
						</div>
					</form>
				</div>
			</div>
			<Feedback :successMessage="$t('configuration_saved')" />
		</div>
	</Layout>
</template>

<script>
	import Layout from "../layouts/Layout.vue";
	import SaveSpinner from "../views/Spinner.vue";
	import Error from "../views/Error.vue";
	import Feedback from "../views/Feedback.vue";
	import cloneDeep from "lodash.clonedeep";
	import Vuex from "vuex";
	//html elements
	import VText from "../views/htmlelements/VText.vue";
	import VCheckbox from "../views/htmlelements/VCheckbox.vue";
	import VSelect from "../views/htmlelements/VSelect.vue";
	import VFile from "../views/htmlelements/VFile.vue";
	import VColorpicker from "../views/htmlelements/VColorpicker.vue";
	import FEC from "../views/htmlelements/FormElementContainer.vue";
	//abstract page
	import Page from "../pages/Page.vue";
	//import { mapMutations } from 'vuex'
	//get an alias to mapState. I map the properties in the store to our computed aliases.
	const ms = Vuex.mapState;

	export default {
		data() {
			return {
				wlv: cloneDeep(this.$store.state.wlv),
				ref_font: [],
				suckerCanvas: null,
				suckerArea: [],
				telnumbers: [],
				recomTelnumbers: [],
				isSucking: false,
				colors: {
					hex: "#000000"
				},
				partnerLogoURL: null,
				partnerLogo: null,
				wlvPath : process.env.VUE_APP_WLVPATH + "?partnerid=" + this.$store.state.partnerid
			};
		},
		components: {
			Layout,
			SaveSpinner,
			Error,
			Feedback,
			VCheckbox,
			VSelect,
			VFile,
			FEC,
			VText,
			VColorpicker
		},
		mixins: [Page],
		props: ["color"],
		methods: {
			saveWLV: async function() {
				this.$v.$touch();
				this.$store.commit("resetui");
				if (this.$v.$invalid) {
					this.submitStatus = "ERROR";
					//console.log("invalid data!");
				} else {
					let wlv = cloneDeep(this.wlv);
					this.$store.commit("setui", { loadingflag: true });
					wlv.partnerLogoImage = this.partnerLogo;
					this.$store.dispatch("savewlv", { wlv: wlv })
					.then(() => {
						this.wlv = cloneDeep(this.$store.state.wlv);
						this.partnerLogo = null;
						this.partnerLogoURL = `/media/partner/${this.wlv.partnerid}/${this.wlv.partnerlogo}`;
					})
					.catch(() => {
						this.$store.commit("setui", { errorflag: true });
					})
					.finally(() => {
						this.$store.commit("setui", { loadingflag: false });
					});
				}
			},
			getWLV: async function() {
				this.$store.commit("setui", { loadingflag: true });
				try {
					await this.$store.dispatch("getwlv", {});
					this.wlv = cloneDeep(this.$store.state.wlv);
					this.partnerLogoURL = `/media/partner/${this.wlv.partnerid}/${this.wlv.partnerlogo}`;
					if(this.wlv.styles == null){
						this.wlv.styles = {
							partnerid: this.$store.state.partnerid,
							colorbackground: "#dddddd",
							colorprimary: "#0082D4",
							colorsecondary: "#F2F2F2",
							colorbutton: "#FF6666",
							colorbuttonhover: "#FF6666",
							colorbuttonfont: "#ffffff",
							colorlink: "#2194CE",
							colorborder: "#337ab7",
							colorfont: "#000000",
							colorfonthighlight: "#FF6666",
							fontfamily: "",
							fontsize: "14",
							blockheader: true,
							blockfooter: true,
							colorheading: "#0082D4"
						}
					}
				} catch {
					this.partnerLogoURL = '';
					this.$store.commit("setui", { errorflag: true });
				} finally{
					this.$store.commit("setui", { loadingflag: false });
				}

			},
			changeLogoName: function(input){
				let image = new Image();
				const reader = new FileReader();

				reader.onload = (e) => {
					image.src = e.target.result;
					if(image.height < 150 && image.width < 250){
						this.partnerLogo = input.files[0];
					}else{
						input.files = null;
					}
				}

				reader.onloadend = () => {
					this.wlv.partnerlogoencoded = reader.result;
				}

				reader.readAsDataURL(input.files[0]); // convert to base64 string
			},
			getWLVpath: function(url){
				switch(this.$store.state.auth.mandant){
					case 101:
					return  process.env.VUE_APP_WLVPATH_101 + url + "?partnerid=" + this.$store.state.partnerid
					case 102:
					return process.env.VUE_APP_WLVPATH_102 + url + "?partnerid=" + this.$store.state.partnerid
					case 104:
					return process.env.VUE_APP_WLVPATH_104 + url + "?partnerid=" + this.$store.state.partnerid
					case 105:
					return process.env.VUE_APP_WLVPATH_105 + url + "?partnerid=" + this.$store.state.partnerid
					case 106:
					return process.env.VUE_APP_WLVPATH_106 + url + "?partnerid=" + this.$store.state.partnerid
					case 107:
					return process.env.VUE_APP_WLVPATH_107 + url + "?partnerid=" + this.$store.state.partnerid
					case 109:
					return process.env.VUE_APP_WLVPATH_109 + url + "?partnerid=" + this.$store.state.partnerid
					case 111:
					return process.env.VUE_APP_WLVPATH_111 + url + "?partnerid=" + this.$store.state.partnerid
					case 112:
					return process.env.VUE_APP_WLVPATH_112 + url + "?partnerid=" + this.$store.state.partnerid
				}
				return "";
			},
			getTelnumbers: async function(){
				this.$store.commit("setui", { loadingflag: true });
				try {
					await this.$store.dispatch("listTelnumbers", {});
					let phonecodes = [];

					switch(this.$store.state.auth.mandant){
						case 104:
							phonecodes = ["BE","DE","NL"];
							break;
						case 105:
							phonecodes = ["BE","DE","NL"];
							break;
						case 106:
							phonecodes = ["BE","DE","NL"];
							break;
						case 107:
							phonecodes = ["BE","DE","NL"];
							break;
						case 109:
							phonecodes = ["ES"];
							break;
						case 111:
							phonecodes = ["PT"];
							break;
						case 112:
							phonecodes = ["FR"];
							break;
						default:
							phonecodes = ["AT","DE","CH"];
					}

					this.telnumbers = cloneDeep(this.$store.state.telnumbers)
					this.recomTelnumbers = this.telnumbers.filter(t => phonecodes.includes(t.countrycode));
				} catch(err) {
					this.$store.commit("setui", {  errorflag: true })
				} finally{
					this.$store.commit("setui", { loadingflag: false })
				}
			},
			getPhoneCountry: function() {
				switch(this.$store.state.auth.mandant){
					case 104:
						return "31"
					case 105:
						return "31"
					case 106:
						return "31"
					case 107:
						return "31"
					default:
						return "49"
				}
			}
		},
		//end methods
		computed: {
			...ms(["ui"])
		},
		//I am executed everytime this component is intialized.
		async created() {
			if((Object.keys(this.$store.state.stammdaten).length===0 || typeof this.$store.state.stammdaten !== 'object') && (this.$store.state.auth.status===1)){
				await this.$store.dispatch('getstammdaten')
			}

			this.ref_font = this.$store.state.stammdaten.fontstyle;
			await this.getWLV(); //loads the wlv data
			await this.getTelnumbers();
		},
		validations: {
			wlv: {
				styles: {
					fontfamily: {}
				}
			}
		}
	};
</script>

<style>
	.partnerLogo img{
		width: 250px;
	}

	.card-body{
		padding: 1rem
	}

	.card-body > *:nth-child(even){
		background-color: #eee;
	}

	.card-body > .row{
		padding: .5rem 0;
		margin-bottom: 0;
	}
</style>
