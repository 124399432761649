<template>
	<Layout>
		<div>
			<SaveSpinner  :loadFlag="ui.loadingflag" />
			<div v-if="error">
				<div class="alert alert-danger">
					<Error />
				</div>
			</div>
			<div v-else-if="loadingflag === false">
				<div class="content">
					<form class="my-form" @submit.prevent="listTransactions">
						<div class="form-group">
							<FEC :n="$t('transactiontype')">
								<VSelect v-model="type" :q="renderSelectOptions(ref_transactiontype)" :value="type" :v="{}"/>
							</FEC>
							<FEC :n="$t('full_text_search')">
								<VText v-model="searchin" maxlength="255" size="15" />
							</FEC>
							<FEC :n="$t('date_filtertype')">
								<label class="radio mr-4 py-2">
									<span class="pr-1">{{$t('bookingdate')}}</span>
									<input type="radio" name="searchin" value="buch" class="check-box" v-model="transactionfilter" />
								</label>
								<label class="radio">
									<span class="pr-1">{{$t('returndate')}}</span>
									<input type="radio" name="searchin" value="travelreturn" class="check-box" v-model="transactionfilter" />
								</label>
							</FEC>
							<FEC :n="$t('dateRange')">
								<div>
									<div class="row">
										<div class="col-6">
											<v-date-picker v-model="dateRange.start" :max-date="dateRange.end">
												<template v-slot="{ inputValue, inputEvents }">
													<input
														class="form-control"
														:value="inputValue"
														v-on="inputEvents"
														:rules="[v => !!v || 'Required']"
													/>
												</template>
											</v-date-picker>
										</div>
										<div class="col-6">
											<v-date-picker v-model="dateRange.end" :min-date="dateRange.start">
												<template v-slot="{ inputValue, inputEvents }">
													<input
														class="form-control"
														:value="inputValue"
														v-on="inputEvents"
														:rules="[v => !!v || 'Required']"
													/>
												</template>
											</v-date-picker>
										</div>
									</div>
									<div v-if="dateRange.start == null || dateRange.end == null" class="error">
										{{$t('validation_date_required')}}
									</div>
								</div>
							</FEC>
						</div>
						<button type="submit" class="mx-auto btn btn-info d-block" :disabled="dateRange.end == null || dateRange.start == null">{{$t('refresh')}}</button>
						<div class="form-group row no-gutters">
							<Feedback class="mx-auto" :successMessage="$t('data_saved')" />
						</div>
					</form>
					<div class="overflow-auto">
						<TransactionTable :transactions="transactions" :transactionType="'transaction'" />
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
import Layout from "../layouts/Layout.vue";
import SaveSpinner from "../views/Spinner.vue";
import Error from "../views/Error.vue";
import Feedback from "../views/Feedback.vue";
import cloneDeep from "lodash.clonedeep";
//import { required, minLength, alpha, numeric } from 'vuelidate/lib/validators'
import Vuex from "vuex";
//get an alias to mapState. I map the properties in the store to our computed aliases.
const ms = Vuex.mapState;
//html elements
import VText from "../views/htmlelements/VText.vue";
import VSelect from "../views/htmlelements/VSelect.vue";
import FEC from "../views/htmlelements/FormElementContainer.vue";
import TransactionTable from "../views/TransactionTable.vue";
//abstract page
import Page from "../pages/Page.vue";

//transactions must be declared in data even though we later set it in listTransactions or any validation won't bind to it! May not be necessar here though since we're not modifying it.
export default {
	data() {
		var days = 86400000; //number of milliseconds in a day
		//enddate for search
		var d = new Date();
		var sd = new Date(d - 90 * days); //90 days ago
		return {
			searchin: "",
			type: "open",
			transactions: cloneDeep(this.$store.state.transactions),
			transactionfilter: "buch",
			ref_transactiontype: this.$store.state.stammdaten.transactiontypes,
			dateRange: {
				start: sd,
				end: d
			}
		};
	},
	computed: {
		...ms(["ui"])
	},
	components: { Error, Layout, SaveSpinner, Feedback, VText, VSelect, FEC, TransactionTable},
	mixins: [Page],
	methods: {
		filterList: function(arrResults) {
			let arrFilteredResults = [];
			let phrase = this.searchin;
			arrResults.forEach(function filterresult(item) {
				if (
					item.customername.toUpperCase().includes(phrase.toUpperCase()) ||
					item.name.toUpperCase().includes(phrase.toUpperCase()) ||
					item.shipname.toUpperCase().includes(phrase.toUpperCase()) ||
					item.bookingnr.toUpperCase().includes(phrase.toUpperCase()) ||
					item.routeplanid
						.toString()
						.toUpperCase()
						.includes(phrase.toUpperCase())
				)
					arrFilteredResults.push(item);
			});
			return arrFilteredResults;
		},
		printDate(dateString){
			if(dateString === null){
				return "";
			}
			let tmp = new Date(dateString);

			return tmp.getFullYear() + '-' + ((tmp.getMonth()+1 < 10) ? '0'+(tmp.getMonth()+1) : tmp.getMonth()+1) + '-' + ((tmp.getDate() < 10) ? '0'+tmp.getDate() : tmp.getDate());
		},
		listTransactions: async function() {
			// https://partner-wondercruises.e-hoi.edtest.local/partner/loginehoi?partnerID=500000000032
			this.$store.commit("setui", { loadingflag: true });
			try {
				await this.$store.dispatch("listtransactions", {
					type: this.type,
					startdate: this.printDate(this.dateRange.start),
					enddate: this.printDate(this.dateRange.end),
					searchin: this.transactionfilter
				});
				this.transactions = this.filterList(
					cloneDeep(this.$store.state.transactions)
				);
			} catch {
				this.$store.commit("setui", { errorflag: true });
			} finally{
				this.$store.commit("setui", { loadingflag: false })
			}

		},
		renderSelectOptions(data){
			if(data == undefined){
				return [];
			}

			return data.map(d => {
				d.text = this.$t("de_"+d.value);
				return d
			});
		}
	},

	//I am executed everytime this component is intialized.
	async created() {
		this.$store.commit("setui", { loadingflag: true });

		if((Object.keys(this.$store.state.stammdaten).length===0 || typeof this.$store.state.stammdaten !== 'object') && (this.$store.state.auth.status===1)){
			await this.$store.dispatch('getstammdaten')
			.then(() => {
				this.ref_transactiontype = this.$store.state.stammdaten.transactiontypes;
			})
		}
		this.listTransactions(); //loads the partner data
	}
};
</script>
<style>
</style>
